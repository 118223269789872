export const hurstLinkedinUrl =
  "https://www.linkedin.com/company/hurst-capital";

export const hurstInstagramUrl = "https://www.instagram.com/hurst.capital/";

export const hurstYoutubeUrl =
  "https://www.youtube.com/channel/UCT57ySzuBhWBaNPuN7OboWA";

export const hurstTwitterUrl = "https://twitter.com/CapitalHurst";

export const appStoreUrl =
  "https://apps.apple.com/us/app/id1489579621#?platform=iphone";

export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=capital.hurst.app_hurst";
